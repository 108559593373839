import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './client/screens/home'
function App () {
    return (
        <Router>
            <div className="app">
                <Switch>
                    <Route path={'/'} exact component={Home}/>
                </Switch>
            </div>
        </Router>
    )
}

export default App
