import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, message, Select, Table, Upload } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Papa from "papaparse";

const fieldSorter = (fields) => (a, b) =>
  fields
    .map((o) => {
      let dir = 1;
      if (o[0] === "-") {
        dir = -1;
        o = o.substring(1);
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
    })
    .reduce((p, n) => (p ? p : n), 0);
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = () => {
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [zones, setZones] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [zone, setZone] = useState(null);
  const [modified, setModifed] = useState({});
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setSpinning(true);
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          let zoneData = {};
          let supplierData = {};
          //results.data = results.data.sort((a,b)=>(a.name || "").trim().localeCompare((b.name || "").trim()))//.sort(fieldSorter(["Pick Zone", "Name"]));
          let modified = {};
          let finalData = [];
          results.data.forEach((item, index) => {
            supplierData[item["Primary Supplier"] || "_Empty"] = true;
            zoneData[item["Pick Zone"] || "_Empty"] = true;
            item.supplier = item["Primary Supplier"] || "_Empty";
            item.zone = item["Pick Zone"] || "_Empty";
            item.qty = item["Qty In Stock"];
            item.qty = item.qty ? parseInt(item.qty) : 0;
            item.newQty = item.qty;
            item.name = item["Name"];
            item.sku = item["SKU*"];
            item.price = item["Average cost price"];
            item.key = index;
            modified[item.key] = item.qty;
            finalData.push(item);
          });
          finalData.sort((a,b)=>(a.name || "").trim().localeCompare((b.name || "").trim()))
          setSuppliers(Object.keys(supplierData));
          setZones(Object.keys(zoneData));
          setData(finalData);
          setFullData(finalData);
          setModifed(modified);
          setSpinning(false);
        },
      });
      return false;
    },
    accept: ".csv",
  };
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: 400,
    },
    // {
    //   title: "Pick Zone",
    //   dataIndex: "zone",
    // },
    {
      title: "QTY",
      dataIndex: "qty",
    },
    {
      title: "ADJUST",
      render: (value, record, index) => {
        return (
          <React.Fragment>
            <Button
              type="primary"
              icon={<MinusOutlined />}
              onClick={(e) => {
                const newModified = {
                  ...modified,
                };
                newModified[record.key]--;
                setModifed(newModified);
              }}
            />
            &nbsp; | &nbsp;
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={(e) => {
                const newModified = {
                  ...modified,
                };
                newModified[record.key]++;
                setModifed(newModified);
              }}
            />
          </React.Fragment>
        );
      },
      width: 120,
    },
    {
      title: "NEW QTY",
      dataIndex: "newQty",
      render: (value, record) => modified[record.key],
    },
    {
      title: "DIFFERENCE",
      dataIndex: "difference",
      render: (value, record) => modified[record.key] - record.qty,
    },
  ];
  const finish = async () => {
    setSpinning(true);
    try {
      let output = [];
      fullData.forEach((item) => {
        if (modified[item.key] - item.qty !== 0) {
          output.push({
            "SKU*": item.sku,
            "Cost Price": item.price,
            "Quantity Changed": modified[item.key] - item.qty,
          });
        }
      });
      let csv = Papa.unparse(output, {
        header: false,
      });
      csv = 'SKU*,"Cost Price","Quantity Changed"\n' + csv;
      const body = new FormData();
      body.append(
        "attachmentFile",
        new Blob([csv], { type: "text/csv;charset=utf-8;" }),
        "uploadstock.csv"
      );
      const response = await fetch(
        //"https://app.scottandrew.com.au/contact_mail.php",
        "/contact_mail.php",
        {
          method: "POST",
          body,
        }
      );
      const result = await response.text();
      if (result === "true") {
        message.success("File processed and sent successfully");
      } else {
        message.error("Error while processing the file");
      }
    } catch (e) {
      console.error("Error while sending to server", {
        e,
      });
    }
    setSpinning(false);
  };
  useEffect(() => {
    const arr = fullData.filter((item) => {
      let result = true;
      if (typeof zone !== "undefined" && zone !== null) {
        result = result && (item.zone || "") === zone;
      }
      if (typeof supplier !== "undefined" && supplier !== null) {
        result = result && (item.supplier || "") === supplier;
      }
      return result;
    });
    /*if (
      (typeof zone !== "undefined" && zone !== null) ||
      (typeof supplier !== "undefined" && supplier !== null)
    ) {*/
      arr.sort((a,b)=>(a.name || "").trim().localeCompare((b.name || "").trim()));
    //}
    setData(arr);
  }, [supplier, zone]);
  return (
    <div className="home page">
      <div className="header">
        <div className="container">
          <div className="left">
            <p className="logo">Stock Count</p>
          </div>
          <div className="right">
            <p className="count">Number of Records : {data.length}</p>
            <div className="actions">
              <Upload {...props}>
                <Button className="primary-btn-text">Load Inventory</Button>
              </Upload>
              <Button className="primary-btn-text" onClick={() => finish()}>
                Finish (Send File)
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="filters">
            <div className="actions mobile">
              <Upload {...props}>
                <Button className="primary-btn-text">Load Inventory</Button>
              </Upload>
              <Button className="primary-btn-text" onClick={() => finish()}>
                Finish (Send File)
              </Button>
            </div>
            <div className="left">
              <p className="count">Number of Records : {data.length}</p>
            </div>
            <div className="right">
              <Select
                options={zones.map((zone) => ({
                  label: zone,
                  value: zone,
                }))}
                placeholder="Pick Zone"
                allowClear={true}
                onChange={(value) => setZone(value)}
              />
              <Select
                options={suppliers.map((supplier) => ({
                  label: supplier,
                  value: supplier,
                }))}
                placeholder="Supplier"
                allowClear={true}
                onChange={(value) => setSupplier(value)}
              />
            </div>
          </div>
          <div className="table">
            <Table
              loading={spinning}
              columns={columns.map((column) => {
                return {
                  key: column.title,
                  dataIndex: column.title,
                  ...column,
                };
              })}
              bordered
              dataSource={data}
              pagination={{
                pageSize: 15,
                //position: ["betopLeft"],
                showSizeChanger: false,
              }}
              scroll={{ y: window.innerHeight - 250 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Main.displayName = "Home";
//Pre process the container with Redux Plugins
export default Main;
